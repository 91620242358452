
<main class="content belender-widget-wrapper h-full p-1 md:p-2">
  <div #widgetCard class="card rounded-none h-fit md:rounded-md widget-boxed p-0 shadow-none md:shadow-md mt-3 max-w-[726px]">
    <vex-page-layout class="belender-page-layout h-full p-0 md:p-4">

      <vex-page-layout-content class="h-auto overflow-y-visible content-layout overflow-x-hidden content-widget p-0 md:p-3"> 
        <router-outlet></router-outlet>
      </vex-page-layout-content>
    
    </vex-page-layout>
    
  </div>
  <div class="footer bg-slate-800 text-white z-50 py-4 px-8 flex flex-col md:flex-row justify-between items-center">
    <div class="copyright mb-4 md:mb-0">
      <span *ngIf="showCopyright">Copyright ©2023 {{copyrightName}}</span>
    </div>

    <ng-container *ngIf="!loadingCustomization">

      <div *ngIf="showCopyright" class="links flex flex-col md:flex-row justify-end items-center">
        <div class="link border-none md:border-r-2 pr-0 md:pr-4">
          <a class="text-sm hover:text-green-500 cursor-pointer" target="_blank" (click)="gotoPolicy($event)">Política de privacidad</a>
        </div>
        <div class="link pl-0 md:pl-4">
         <a class="text-sm hover:text-green-500 cursor-pointer" target="_blank" (click)="gotoLegal($event)"> Aviso legal</a>
        </div>
      </div>

      <div *ngIf="!showCopyright" class="links flex flex-col md:flex-row justify-end items-center">
        <div class="link pl-0 md:pl-4">
         <a class="text-sm hover:text-green-500 cursor-pointer" target="_blank" (click)="gotoLegal($event)"> Aviso legal</a>
        </div>
      </div>
    </ng-container>
    


  </div>
</main>