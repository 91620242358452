import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { UtilsService } from 'src/services/utils.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { TranslationService } from 'src/services/translation.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationsService } from 'src/services/notifications.service';
import { TrelloService } from 'src/services/trello.service';
import { LoginService } from 'src/services/login.service';
import { AuthService } from 'src/services/auth.service';
import { DialogModule } from 'src/@vex/components/dialog/dialog.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HandleErrorsService } from 'src/services/handle-errors.service';
import { AutofirmaModule,AF_DEFAULT_PARAMS } from 'ngx-autofirma-lib';
import { TextMaskModule } from 'angular2-text-mask';

const firebaseConfig = {
  apiKey: "AIzaSyDy7m74amvuLzKW4sjEHAS8Epu4Q-UE9uk",
  authDomain: "belender-test.firebaseapp.com",
  projectId: "belender-test",
  storageBucket: "belender-test.appspot.com",
  messagingSenderId: "547098308614",
  appId: "1:547098308614:web:61ac7bd252b92a3854d387",
  databaseURL: "https://belender-test-default-rtdb.europe-west1.firebasedatabase.app/"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
let lang = localStorage.getItem('belender-language')
export function setupTranslateServiceFactory(service: TranslationService): Function {
  return () => service.use(lang || 'sp');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    VexModule,
    CustomLayoutModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    DialogModule,
    AutofirmaModule.forRoot(),
    TextMaskModule
  ],
  providers: [
    TrelloService,
    NotificationsService,
    UtilsService,
    AuthService,
    LoginService,
    HandleErrorsService,
    TranslationService,
      {
        provide: APP_INITIALIZER,
        useFactory: setupTranslateServiceFactory,
        deps: [
          TranslationService
        ],
        multi: true
      },
      { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
      {provide: AF_DEFAULT_PARAMS, useValue: {headless: true}}
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
