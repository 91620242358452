// generated on build time, from script >> iac/scripts/build-environment-conf.sh

export const environment = {
  production: true,
  scraperMock: false,
  env: 'pro',
  baseUrl: 'https://api.opendata.bcds.net',
  notificationsBaseUrl: 'wss://notifications.opendata.bcds.net'
};


